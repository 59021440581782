import React from 'react';
import '../styles/about.scss';
import HeaderTitle from '../components/HeaderTittle';
import areaTreatment from '../images/logo-icon.svg';
import AboutSingleItem from '../components/AboutSingleItem';
import {useStaticQuery, graphql} from 'gatsby';
// import JSONData from "../../content/My-JSON-Content.json"
import JSONData from '../../content/about.json';

const About = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      mZareba: file(name: { eq: "marcin" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sZareba: file(name: { eq: "sara_zareba" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      saraD: file(name: { eq: "sara_d" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <section id='o-nas' className='about container'>
      <HeaderTitle img={areaTreatment}>O nas</HeaderTitle>
      <div className='about-container'>
        <AboutSingleItem
          id="mzareba"
          data={JSONData.content[0]}
          img={data.mZareba.childImageSharp.fluid}
        />
        <AboutSingleItem
          id="szareba"
          data={JSONData.content[1]}
          img={data.sZareba.childImageSharp.fluid}
          reverse={true}
        />
      </div>
    </section>
  );
};
export default About;
